

import styled from 'styled-components';

export const LandingPage = styled.div`
@media screen and (max-width: 470px) {
    width:100%;
    margin-top:50px;
  }

`
export const LogoHeader = styled.div`
display: flex;
justify-content: center;
margin-top:50px;
img {
    width: 201px;
}
@media  (max-width: 470px) {
  
  img {
      width: 150px;
  }
}
`
export const Body = styled.div`
margin: 100px;
justtify-content: center;
@media  (max-width: 470px) {
  margin:0px;
  margin-top:50px;
  // padding-left: 23px;
}
`
export const ContainerSingleImage = styled.div`
display:flex;
justify-content:center;
@media  (max-width: 470px) {
  margin: 0px 0px 70px 0px;
    .singleImage {
        width: 90%!important;
    }
  }
.singleImage {
    width: 100%;
    padding-left:25px;
}
`
export const Container = styled.div`
display: flex;
@media screen and (max-width: 470px) {
    flex-direction:column;
    padding-left:20px;
  }
justify-content: space-evenly;
margin-bottom:100px;
@media  (max-width: 470px) {
    .leftImage {
        width 300px!important;
        height:300px!important;
        margin-bottom:22px;
        padding-left: 10%;
    }
    .rightImage {
      width 400px!important;
      height:400px!important;
  }
  }
.leftImage {
    width 650px;
    height:650px;
}
.rightImage {
    width 650px;
    height:650px;
}
`
export const Footer = styled.div`
img {
  width: 100%
}
@media  (max-width: 470px) {
    .img {
        width: 100%!important;
    }
  }
`