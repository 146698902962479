import React from 'react'
import { LogoHeader,LandingPage, Body, Container, ContainerSingleImage } from './style'
import logo from './assets/logo.png'
import firstL from './assets/firstL.png'
import firstR from './assets/firstR.png'
import secondL from './assets/secondL.png'
import secondR from './assets/secondR.png'
import bloc3 from './assets/bloc3.png'
import thirdL from './assets/thirdL.png'
import thirdR from './assets/thirdR.png'
import fourthL from './assets/fourthL.png'
import fourthR from './assets/fourthR.png'
import fiveL from './assets/fiveL.png'
import fiveR from './assets/fiveR.png'
// import footer from './assets/footer.png'
import Footer from './components/Footer'

const App = () => {
  return (
    <LandingPage>
      <LogoHeader>
      <img src={logo} alt="Logo" />
      </LogoHeader>
      <Body>
        <Container>
      <img className='leftImage' src={firstL} alt="Logo" />
      <a href='https://signup.mymotheragency.com/'>
      <img className='rightImage' src={firstR} alt="Logo" />
      </a>
        </Container>
        <Container>
      <img className='leftImage' src={secondL} alt="Logo" />
      <a href='https://signup.mymotheragency.com/'>
      <img className='rightImage' src={secondR} alt="Logo" />
      </a>
        </Container>
        <ContainerSingleImage>
        <a style={{    display: "flex",justifyContent: "center"}} href='https://signup.mymotheragency.com/'>
      <img style={{width:'75%'}}  className='singleImage' src={bloc3} alt="Logo" />
      </a>
        </ContainerSingleImage>
        <Container>
      <img  className='leftImage' src={thirdL} alt="Logo" />
      <a href='https://signup.mymotheragency.com/'>
      <img className='rightImage' src={thirdR} alt="Logo" />
      </a>
        </Container>
        <Container>
      <img  className='leftImage' src={fourthL} alt="Logo" />
      <a href='https://signup.mymotheragency.com/'>
      <img className='rightImage' src={fourthR} alt="Logo" />
      </a>
        </Container>
        <Container>
      <img  className='leftImage' src={fiveL} alt="Logo" />
      <a href='https://signup.mymotheragency.com/'>
      <img className='rightImage' src={fiveR} alt="Logo" />
      </a>
        </Container>
      </Body>
      <Footer />
      {/* <Footer>
      <img className='footerImage' src={footer} alt="Logo" />
      </Footer>
       */}
    </LandingPage>
  )
}

export default App