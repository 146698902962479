import React from 'react'
import './style.css'
import { GrFacebookOption } from "react-icons/gr";
import { GrInstagram } from "react-icons/gr";
import { FaEnvelope } from "react-icons/fa";
import footer from '../assets/logo_footer.png'

const Footer = () => {
    return (
        
            <div className="wpb_wrapper">
                        <div className="text_footer">
                            <div className="logo">
                                <img className="logo_black_footer" src={footer} />
                                {/* <noscript>
                                                <img src="/wp-content/themes/mma/img/logo_footer.png" className="logo_black_footer" />
                                            </noscript> */}
                            </div>
                            <div className="copyright">
                                <p>© 2022 My Mother Agency</p>
                            </div><div className="lien">
                                <p>
                                    <a href="https://www.mymotheragency.com/privacy-policy/">Privacy policy</a>
                                    <span>-</span>
                                    <a href="https://www.mymotheragency.com/terms-of-service/">Terms of service</a>
                                    {/* <span>-</span> */}
                                    {/* <select name="lang_choice_1" id="lang_choice_1">
                                                    <option value="fr">Français</option>
                                                    <option value="en" defaultValue>English</option>
                                                    <option value="nl">Nederlands</option>
                                                    <option value="ru">Русский</option>
                                                    <option value="es">Español</option>
                                                    <option value="pt">Português</option>
                                                    <option value="de">Deutsch</option>
                                                    <option value="it">Italiano</option>
                                                    <option value="pl">Polski</option>
                                                </select> */}
                                </p>
                            </div>
                            <div className="social">
                                <ul className="list_social">
                                    <li><a href="https://www.facebook.com/mymotheragency/"><GrFacebookOption /></a></li>
                                    <li><a href="https://www.instagram.com/mymotheragency/"><GrInstagram /></a></li>
                                    <li><a href="mailto:contact@mymotheragency.com"><FaEnvelope /></a></li>
                                </ul>
                            </div>
                        </div>
                   
            
        </div>
    )
}

export default Footer